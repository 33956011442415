<!--
 * @Author: xiaziwen
 * @Date: 2021-01-13 13:13:31
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-13 13:28:03
 * @FilePath: \crm-education\src\views\teacher\task\c\TeacherTaskItem.vue
-->
<template>
  <div class="teacher-task-item mb-10">
    <div class="task-title d-flex justify-between align-center mb-15">
      <p class="fs-16 fw-bold">毛泽东思想和中国特色</p>
      <a-tag color="orange">新</a-tag>
    </div>
    <slot :item="item">
      <p class="fs-14 color-gray">开始时间：2019-11-11 14：30</p>
      <div class="d-flex justify-between align-center">
        <p class="fs-14 color-gray">提交数：<span class="color-black">15</span>/20</p>
        <p class="fs-14 color-gray">待批：<span class="fs-16 color-black">8</span>份</p>
      </div>
    </slot>
    <slot name="btn-wrap" :item="item">
    </slot>
  </div>
</template>

<script>
export default {
  name: 'teacer_task_item',
  props: {
    item: {
      type: Object
    }
  }
}
</script>

<style lang="scss" scoped>
.teacher-task-item {
  width: 100%;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid #d7dcdc;
  overflow: hidden;
}
</style>