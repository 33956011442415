<!--
 * @Author: xiaziwen
 * @Date: 2021-01-13 11:56:42
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-13 13:52:45
 * @FilePath: \crm-education\src\views\teacher\task\index.vue
-->
<template>
  <div class="teacher-task">
    <a-card class="cus-card no-border" :bordered="false">
      <template slot="title">
        <div
          class="section-header dec d-flex align-center justify-between flex-wrap"
        >
          <h2 class="base-title fs-18">作业</h2>
          <div class="header-right d-flex align-center">
            <p class="fs-14 color-blue link plus ml-20">创建作业</p>
            <p class="fs-14 color-blue link zyk  ml-20">作业库</p>
            <p class="fs-14 color-gray link delete  ml-20">回收站</p>
          </div>
        </div>
      </template>
      <div class="py-5 d-flex">
        <a-select v-model="selectKey" v-if="panelType === 1">
          <a-select-option value="1">一班</a-select-option>
          <a-select-option value="2">二班</a-select-option>
        </a-select>
        <p class="back-btn link hover-color-blue" v-else>返回</p>
      </div>
      <div class="teacher-task-content py-10">
        <template v-if="panelType === 1">
          <a-row :gutter="20">
            <a-col :md="{span: 8}" :xs="{span: 12}" v-for="i in 6" :key="i">
              <teacher-task-item>
                <template slot="btn-wrap">
                  <div class="d-flex justify-between align-center pt-15">
                    <a-button class="cus-button" type="primary">重新发放</a-button>
                    <a-button type="primary" @click="onTaskListView">查看</a-button>
                  </div>
                </template>
              </teacher-task-item>
            </a-col>
          </a-row>
        </template>
        <template v-else>
          <a-row :gutter="20">
            <a-col :md="{span: 8}" :xs="{span: 12}" v-for="i in 6" :key="i">
              <teacher-task-item>
                <template slot-scope="scope">
                  <p class="fs-14 color-gray">满分：<span class="fs-16 color-black">100{{scope.score}}</span>分</p>
                  <p class="fs-14 color-gray">创建时间：2019-11-11 14：30</p>
                </template>
                <template slot="btn-wrap">
                  <div class="d-flex justify-between align-center pt-15">
                    <a-button class="cus-button" type="primary">查看</a-button>
                    <a-button class="cus-button" type="primary">编辑</a-button>
                    <a-button type="primary">发布</a-button>
                  </div>
                </template>
              </teacher-task-item>
            </a-col>
          </a-row>
        </template>
      </div>
    </a-card>
  </div>
</template>

<script>
import TeacherTaskItem from './c/TeacherTaskItem'
export default {
  name: 'teacher_task',
  components: {
    TeacherTaskItem
  },
  data () {
    return {
      selectKey: '1',
      panelType: 1
    }
  },
  methods: {
    onTaskListView () {
      this.panelType = 2
    }
  }
}
</script>

<style lang="scss" scope>
.teacher-task {
  .header-right {
    p {
      padding-right: 20px;
    }
    .zyk {
      position: relative;
      &::before {
        content: "";
        display: block;
        width: 18px;
        height: 18px;
        background: url("../../../assets/img/task-link.png") no-repeat center
          center;
        position: absolute;
        right: 0;
        top: 2px;
      }
    }
    .plus {
      position: relative;
      &::before {
        content: "";
        display: block;
        width: 18px;
        height: 18px;
        background: url("../../../assets/img/task-link-plus.png") no-repeat
          center center;
        position: absolute;
        right: 0;
        top: 2px;
      }
    }
    .delete {
      position: relative;
      &::before {
        content: "";
        display: block;
        width: 18px;
        height: 18px;
        background: url("../../../assets/img/task-link-delete.png") no-repeat
          center center;
        position: absolute;
        right: 0;
        top: 2px;
      }
    }
  }
  .back-btn {
    padding-left: 20px;
    position: relative;
    &::before {
      content: "";
      display: block;
      width: 18px;
      height: 18px;
      background: url("../../../assets/img/left-arrow.png") no-repeat center
        center;
      position: absolute;
      left: 0;
      top: 2px;
    }
  }
}
</style>
